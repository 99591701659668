import React from 'react';

function SelfImage() {
	return (
		<div className="self-image">
			<img
				className="self-image__img"
				src="/images/me/IMG-20200701-WA0166.png" //TODO
				alt="self"
			/>
		</div>
	);
}

export default SelfImage;
