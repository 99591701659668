import React from 'react';

function ContentTitle(props) {
	return (
		<div className="section-card__content__title">
			Software Development Internship <br />
			Jan 2019 - Apr 2019
		</div>
	);
}

export default ContentTitle;
