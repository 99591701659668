import React from 'react';

function Technology(props) {
	return (
		<div className="gallery__item__backdrop__technologies__item">
			{props.txt}
		</div>
	);
}

export default Technology;
